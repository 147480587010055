import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;
export let statusCancel;

export const list = async (options) => {
    const { sort, direction, filters, status, results, offset } = options;

    const body = {
        sort,
        direction,
        filters,
        status
    };

    const url = `${process.env.REACT_APP_API_URL}/articulos/list?results=${results}&offset=${offset || 0}`;

    try {
        const request = await axios.post(url, body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );

        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const getBySku = async (sku) => {
    try {
        const request = await axios.get(`${process.env.REACT_APP_API_URL}/articulos/sku/${sku}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const setStatus = async (status = '', elements = []) => {
    try {
        Store.dispatch({ type: 'LOADING' });
        const request = await axios.post(`${process.env.REACT_APP_API_URL}/articulos/status/${status}`,
            {
                elements,
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const setStatusBySKU = async (status = '', barcode) => {
    try {
        Store.dispatch({ type: 'LOADING' });
        const request = await axios.post(`${process.env.REACT_APP_API_URL}/articulos/statusBySku/${status}`,
            {
                barcode,
            },
            {
                cancelToken: new CancelToken(function executor(c) {
                    statusCancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const setStatusByCSV = async (status, type, data) => {
    const formData = new FormData();

    formData.append('file', data);
    formData.append('type', type);


    try {
        Store.dispatch({ type: 'LOADING' });
        const request = await axios.post(`${process.env.REACT_APP_API_URL}/articulos/statusByCSV/${status}`, formData);
        Store.dispatch({ type: 'LOADED' });

        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const setStatusByBarcodeCSV = async (status = '', data) => {
    const formData = new FormData();

    formData.append('file', data);


    try {
        Store.dispatch({ type: 'LOADING' });
        const request = await axios.post(`${process.env.REACT_APP_API_URL}/articulos/statusByBarcodeCSV/${status}`, formData);
        Store.dispatch({ type: 'LOADED' });

        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const exportArticles = async (filters) => {
    try {
        Store.dispatch({ type: 'LOADING' });
        const request = await axios.post(`${process.env.REACT_APP_API_URL}/articulos/export`, { filters });
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const revertStatus = async (id, status) => {
    try {
        Store.dispatch({ type: 'LOADING' });
        const request = await axios.post(`${process.env.REACT_APP_API_URL}/articulos/revert`, { id, status });
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const triggerProcess = async () => {
    try {
        Store.dispatch({ type: 'LOADING' });
        const request = await axios.get(`${process.env.REACT_APP_API_URL}/articulos/process`);
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};