import Store from '../reducers';

const checkRole = (userRoles) => {
    const state = Store.getState();
    const {permissions = []} = state?.auth?.user;

    let roles = [];

    if (Array.isArray(userRoles)) {
        roles = [...userRoles];
    } else {
        roles = [userRoles];
    }

    for (let i = 0; i < roles.length; i += 1) {
        if (!permissions.includes(roles[i])) {
            return false;
        }
    }
    return true
}

export default checkRole;
