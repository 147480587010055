import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;
export let cancelList;

export const update = async (data) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/update`;

    try {
        const request = await axios.post(url, data,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'USER_DATA', user: request.data.user });
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const setProfilePic = (data) => {
    Store.dispatch({ type: 'LOADING' });
    let formData = new FormData();

    for (let k in data) {
        formData.append(k, data[k]);
    }

    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/admin/setProfilePic`, formData)
            .then(
                res => {
                    Store.dispatch({ type: 'USER_IMAGE', image: res.data.file });
                    Store.dispatch({ type: 'LOADED' });
                    resolve(res.data);
                },
                err => {
                    Store.dispatch({ type: 'LOADED' });
                    if (err.response.data.error === expired) {
                        Store.dispatch({ type: 'LOGOUT' });
                    }
                    reject(err.response.data);
                }
            );
    })
};

export const init = async () => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/init`;

    try {
        const request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );

        if (!request?.data?.admin?.is_active) {
            Store.dispatch({ type: 'LOGOUT' });
            return false;
        }

        if (request?.data?.brand?.name) {
            document.title = `Grisino AFP | ${request.data.brand.name}`;
        }

        Store.dispatch({ type: 'USER_DATA', user: request.data.admin });
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const getAdmins = async (options) => {
    const { sort, direction, filters, status, results, offset } = options;

    const body = {
        sort,
        direction,
        filters,
        status
    };
    
    const url = `${process.env.REACT_APP_API_URL}/admin/getAll?results=${results}&offset=${offset}`;

    try {
        const res = await axios.post(url, body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancelList = c;
                })
            }
        );

        return res.data;
    } catch (err) {
        console.error(err);
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err
    }
};

export const toggleAdminStatus = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/status/${id}`;

    try {
        const res = await axios.put(url, {},
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );

        return res.data;
    } catch (err) {
        console.error(err);
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err
    }
};

export const getAdminById = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/get/${id}`;

    try {
        const res = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );

        return res.data;
    } catch (err) {
        console.error(err);
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err
    }
};

export const createAdmin = async (data) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/create`;

    try {
        const request = await axios.post(url, data,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const editAdmin = async (id, data) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/edit/${id}`;

    try {
        const request = await axios.post(url, data);
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const resetPassword = async (id) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/password/${id}`;

    try {
        const request = await axios.patch(url, {},
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const changePassword = async (oldPassword, password, confirm) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/password`;

    try {
        const request = await axios.put(url, {oldPassword, password, confirm},
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const enableTFA = async () => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/tfa/enable`;

    try {
        const request = await axios.post(url, {},
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const validateTFA = async (otp) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/tfa/validate`;

    try {
        const request = await axios.post(url, { otp },
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'USER_TFA_ENABLED' });
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};